import { useEffect } from 'react'
import { DateTime } from 'luxon'
import { usePartySize } from './usePartySize'
import { useUpdateQueryParams } from './useUpdateQueryParams'
import { findClosestFutureTime } from '../components/ReservationDetailEntryView/utils/findClosestFutureTime'
import { useReservationInfo } from './useReservationInfo/useReservationInfo'
import { RestaurantInfo } from '../api/restaurant/getRestaurant'
import { useGetDateTimeParam } from './useGetDateTimeParam/useGetDateTimeParam'
import { parseEpochOrIsoToUtc } from '../utils/parseEpochOrIsoToUtc'

export const useSetupReservationParams = (restaurant: RestaurantInfo) => {
  const updateQueryParams = useUpdateQueryParams()
  const { defaultPartySizeIfNoneChosen, partySize, partySizeParam } =
    usePartySize()
  const getDateTimeParam = useGetDateTimeParam()

  const timeSlotParam = getDateTimeParam('timeSlot')
  const timeSlotDateTime = parseEpochOrIsoToUtc(timeSlotParam)
  const timeSlotString = timeSlotDateTime.toLocal().toISO()
  const isTimeSlotValid = timeSlotDateTime.isValid
  const dateTimeString = getDateTimeParam('dateTime')
  const utcDateTime = DateTime.fromISO(dateTimeString).toUTC()
  const isDateTimeValid = utcDateTime.isValid

  const { hoursOptions } = useReservationInfo(
    restaurant,
    DateTime.fromISO(dateTimeString)
  )

  useEffect(() => {
    // if user omits OR manipulates partySize param to be not a number, set default party size
    if (!partySizeParam) {
      updateQueryParams({
        partySize: defaultPartySizeIfNoneChosen.toString()
      })
      return
    }
    // if the URL has a timeSlot query param (linked from RWG or LBT)
    // use that timeSlot value to search for availabilities on the page
    if (isTimeSlotValid) {
      updateQueryParams({
        dateTime: timeSlotString
      })
      // if user omits OR manipulates dateTime param to be not a valid date, set to closest future time
    } else if (!isDateTimeValid && hoursOptions) {
      const closestFutureTime = findClosestFutureTime(
        hoursOptions,
        restaurant.timezone
      )
      updateQueryParams({
        dateTime: closestFutureTime.toISO()
      })
    }
    // if user manipulates partySize param to a number outside of minPartySize to maxPartySize range
    // set to either minPartySize or maxPartySize
    if (partySizeParam !== partySize) {
      updateQueryParams({
        partySize: partySize.toString()
      })
    }
  }, [
    defaultPartySizeIfNoneChosen,
    partySizeParam,
    partySize,
    updateQueryParams,
    isDateTimeValid,
    hoursOptions,
    restaurant.timezone,
    isTimeSlotValid,
    timeSlotString
  ])
}
