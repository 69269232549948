import axios from 'axios'
import { DateTime } from 'luxon'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { NvResponse, RequestName, RequestURL } from '../baseModels'
import { createG2Request } from '../createRequest'
import { useGetBooking } from '../bookings/useGetBooking'
import { useGetExperience } from '../experiences/useGetExperience'

interface GetDayInfoQueryParams {
  bookableId: string | null
}

interface GetDayInfoPathParams {
  businessDate: string
}

export interface DayInfo {
  onlineWaitlistBlocked: boolean
  onlineReservationsBlocked: boolean
  blockedReason: string
  specialDateName: string
  specialDateDescription: string
  scheduleName: string
  scheduleDescription: {
    name: string | null
    description: string | null
  } | null
  minPartySize: number
  maxPartySize: number
}

const getDayInfo = (
  restaurantGuid: string,
  params: GetDayInfoPathParams,
  bookableId: string | null
) =>
  axios
    .request<NvResponse<DayInfo>>(
      createG2Request<GetDayInfoQueryParams>(
        RequestURL[RequestName.GET_DAY_INFO],
        { 'Toast-Restaurant-External-ID': restaurantGuid },
        { businessDate: params.businessDate },
        { bookableId }
      )
    )
    .then((response) => response.data.results[0])

export const useGetDayInfo = (restaurantGuid: string, date: DateTime) => {
  const businessDate = date.toFormat('yyyy-MM-dd')
  const { experience } = useGetExperience()
  const { bookingGuid = '' } = useParams()
  const { data: bookingData } = useGetBooking(bookingGuid, false)

  const experienceGuid = bookingData?.bookable?.guid || experience?.guid || null

  return useQuery(
    ['dayInfo', restaurantGuid, businessDate, experienceGuid],
    () =>
      getDayInfo(
        restaurantGuid,
        { businessDate: businessDate },
        experienceGuid
      ),
    { enabled: date.isValid }
  )
}
