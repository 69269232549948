import * as React from 'react'
import { useAuth } from '@toasttab/do-secundo-guest-authentication'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { Reservation } from '../../api/bookings/getBooking'
import { Form, FormikProvider, useFormik } from 'formik'
import {
  GuestAccountDataEntryFormValues,
  getDataEntryInitialValues
} from './models'
import { dataEntryValidationSchema } from './validationSchema'
import { SubmitButton } from '@toasttab/buffet-pui-forms'
import { LegalTray } from '../LegalTray'
import { AccountCreationLegalText } from './AccountCreationLegalText'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { ToastBrand } from '@toasttab/buffet-pui-toast-logo'

const formId = 'GuestDataEntry'

type GuestDataEntryProps = {
  restaurant: RestaurantInfo
  guestDataToCollect: string[]
  guestContactCollectionSucceeded: (guestDataToCollect: any) => void
  booking?: Reservation
  fromBookingStep: boolean
}

export function GuestDataEntry({
  restaurant,
  guestDataToCollect,
  guestContactCollectionSucceeded,
  booking,
  fromBookingStep
}: GuestDataEntryProps) {
  const { confirmPasswordless } = useAuth()
  const initialValues = getDataEntryInitialValues(booking)
  const validationSchema = dataEntryValidationSchema
  const [isError, setIsError] = React.useState(false)
  const [isLegalTrayActive, setIsLegalTrayActive] = React.useState(false)

  const handleBlurCapture = () => {
    setIsLegalTrayActive(false)
  }

  const onSubmit = async (values: GuestAccountDataEntryFormValues) => {
    const email = values.email
    const firstName = values.firstName
    const lastName = values.lastName
    try {
      const response = await confirmPasswordless('TOAST_TABLES', {
        email,
        firstName,
        lastName
      })

      const responseType = response.data?.confirmGuest?.__typename
      if (responseType === 'ConfirmGuestSuccess') {
        setIsError(false)
        guestContactCollectionSucceeded(response.data?.confirmGuest)
      } else {
        setIsError(true)
      }
    } catch (err) {
      setIsError(true)
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true
  })

  return (
    <FormikProvider value={formik}>
      <Form id={formId}>
        <ToastBrand size='xxs' strokeColor='text-brand-50' className='mb-4' />
        <h3 className='type-large font-semibold mb-2'>
          Complete your Toast Account profile
        </h3>
        <div className='flex flex-col gap-4'>
          <p className='text-secondary'>
            {!fromBookingStep && (
              <>
                We just need a little more information to create your Toast
                Account and for this and future bookings.
              </>
            )}
            {fromBookingStep && (
              <>
                We just need a little more info to create your account for
                express booking.
              </>
            )}
          </p>
          {guestDataToCollect.includes('FIRST_NAME') && (
            <TextInput
              testId='login-first-name-entry'
              containerClassName='w-full'
              label='First name'
              required
              autoFocus
              name='firstName'
              placeholder='First name'
              value={formik.values.firstName}
              invalid={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              errorText={formik.touched.firstName && formik.errors.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          )}
          {guestDataToCollect.includes('LAST_NAME') && (
            <TextInput
              testId='login-last-name-entry'
              containerClassName='w-full'
              label='Last name'
              required
              name='lastName'
              placeholder='Last name'
              value={formik.values.lastName}
              invalid={
                formik.touched.lastName && Boolean(formik.errors.lastName)
              }
              errorText={formik.touched.lastName && formik.errors.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          )}
          {guestDataToCollect.includes('EMAIL') && (
            <>
              <TextInput
                testId='login-email-entry'
                containerClassName='w-full'
                label='Email'
                required
                name='email'
                type='email'
                placeholder='Email'
                value={formik.values.email}
                invalid={formik.touched.email && Boolean(formik.errors.email)}
                errorText={formik.touched.email && formik.errors.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onFocus={() => setIsLegalTrayActive(true)}
                onBlurCapture={handleBlurCapture}
              />
            </>
          )}
        </div>
        <div>
          <LegalTray isVisible={isLegalTrayActive && !fromBookingStep}>
            <>
              Your email will be used to provide digital receipts, account
              communications, and marketing messages from both Toast and
              restaurants you visit unless you unsubscribe.
            </>
          </LegalTray>
        </div>

        {isError && (
          <div className='m-auto w-full flex items-start'>
            <Alert showIcon variant='error' className='w-full'>
              Oops! Looks like we&apos;ve hit a little bump; please try again.
            </Alert>
          </div>
        )}

        <div className='flex flex-col gap-3 w-full my-4'>
          <p className='text-secondary type-caption'>
            <AccountCreationLegalText restaurant={restaurant} />
          </p>
          <SubmitButton
            form={formId}
            testId='guest-data-entry-button'
            size='base'
            className='w-full py-2.5 mb-2 gap-3'
            disabled={
              formik.isSubmitting ||
              (!(formik.isValid && formik.dirty) && !booking)
            }
          >
            Continue
          </SubmitButton>
        </div>
      </Form>
    </FormikProvider>
  )
}
