import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useRestaurantContext } from '../../contexts/RestaurantContext'
import { Experience } from '../../api/experiences/model'

export const ViewExperienceLink = ({
  experience,
  className
}: {
  experience: Experience
  className?: string
}) => {
  const { shortUrl } = useRestaurantContext()
  return (
    <Button
      as={Link}
      to={`/${shortUrl}/experience/${experience.slug}`}
      className={className}
      size='sm'
      target='_blank'
      rel='noreferrer'
    >
      View
    </Button>
  )
}
