import { useSearchParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useGetDayInfo } from '../api/restaurant/useGetDayInfo'
import { useGetDateTimeParam } from './useGetDateTimeParam/useGetDateTimeParam'
import {
  DEFAULT_MAX_PARTY_SIZE,
  DEFAULT_MIN_PARTY_SIZE
} from '@local/constants'

export const usePartySize = () => {
  const { restaurantGuid } = window.TT_GLOBALS

  const [searchParams] = useSearchParams()
  const partySizeParam = Number(encodeURI(searchParams.get('partySize') || ''))

  const getDateTimeParam = useGetDateTimeParam()
  const dateTimeParam = getDateTimeParam('dateTime')
  const date = DateTime.fromISO(dateTimeParam).startOf('day')
  const { data: dayInfo } = useGetDayInfo(restaurantGuid, date)

  const minPartySize = dayInfo?.minPartySize || DEFAULT_MIN_PARTY_SIZE
  const maxPartySize = dayInfo?.maxPartySize || DEFAULT_MAX_PARTY_SIZE

  const defaultPartySizeIfNoneChosen = Math.min(
    Math.max(2, minPartySize),
    maxPartySize
  )

  // if user manipulates partySize param to 999999, we adjust to maxPartySize set for restaurant
  // if user manipulates partySize param to 0, we adjust to defaultPartySizeIfNoneChosen
  const partySize = Math.max(
    Math.min(partySizeParam || defaultPartySizeIfNoneChosen, maxPartySize),
    minPartySize
  )

  return { partySize, partySizeParam, defaultPartySizeIfNoneChosen }
}
