import React from 'react'
import { BookingType } from '../../api/baseModels'
import { Reservation } from '../../api/bookings/getBooking'
import { DepositStatus } from '../../api/deposits/model'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { DepositRefundText } from '../DepositRefundText/DepositRefundText'
import { NewLineFormatter } from '../NewLineFormatter/NewLineFormatter'
import { DividerLine } from '../DividerLine/DividerLine'

type TextFooterProps = {
  restaurant: RestaurantInfo
  booking?: Reservation
  showLegal?: boolean
  showDepositPolicy?: boolean
  bookingType: BookingType
}

export const TextFooter = ({
  restaurant,
  booking,
  showLegal = false,
  showDepositPolicy = false,
  bookingType
}: TextFooterProps) => (
  <div className='flex flex-col gap-4'>
    <DividerLine />
    <div className='flex flex-col gap-1'>
      <h2 className='type-large font-semibold'>About</h2>
      <div className='text-secondary'>
        <NewLineFormatter text={restaurant.description} />
      </div>
    </div>
    <DividerLine />
    {showDepositPolicy && booking?.depositStatus === DepositStatus.PAID ? (
      <div className='flex flex-col gap-1'>
        <h2 className='type-large font-semibold'>Deposit policy</h2>
        <div className='flex flex-col gap-2'>
          {booking?.depositBookableConfigSnapshot?.depositPolicy && (
            <div className='text-secondary'>
              <NewLineFormatter
                text={booking.depositBookableConfigSnapshot.depositPolicy}
              />
            </div>
          )}
          {booking?.depositBookableConfigSnapshot
            ?.cancellationRefundableTimeframe != null && (
            <DepositRefundText
              timeFrame={
                booking.depositBookableConfigSnapshot
                  .cancellationRefundableTimeframe
              }
            />
          )}
        </div>
      </div>
    ) : restaurant.onlineReservationsEnabled &&
      bookingType === BookingType.RESERVATION &&
      restaurant.reservationPolicy?.trim().length !== 0 ? (
      <div className='flex flex-col gap-1'>
        <h2 className='type-large font-semibold'>Reservation Policy</h2>
        <div className='text-secondary'>
          <NewLineFormatter text={restaurant.reservationPolicy} />
        </div>
      </div>
    ) : null}
    {bookingType === BookingType.WAITLIST &&
      restaurant.waitlistPolicy?.trim().length !== 0 && (
        <div className='flex flex-col gap-1'>
          <h2 className='type-large font-semibold'>Waitlist Policy</h2>
          <div className='text-secondary'>
            <NewLineFormatter text={restaurant.waitlistPolicy} />
          </div>
        </div>
      )}
    {showLegal && (
      <div className='flex flex-col gap-1'>
        <h2 className='type-large font-semibold'>Privacy</h2>
        <p className='type-default text-secondary'>
          This booking is subject to Toast&apos;s{' '}
          <a
            className='text-link hover:text-link-hover'
            target='_blank'
            rel='noreferrer'
            href='https://pos.toasttab.com/terms-of-service#diner-tos'
          >
            Guest Terms of Service{' '}
          </a>
          and your information will be processed pursuant to Toast&apos;s{' '}
          <a
            className='text-link hover:text-link-hover'
            target='_blank'
            rel='noreferrer'
            href='https://pos.toasttab.com/privacy'
          >
            Privacy Statement
          </a>
          .
        </p>
      </div>
    )}
  </div>
)
