import { Alert } from '@toasttab/buffet-pui-alerts'
import { SubmitButton } from '@toasttab/buffet-pui-forms'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Modal } from '@toasttab/buffet-pui-modal'
import { useSnackBar } from '@toasttab/buffet-pui-snackbars'
import { useAuth } from '@toasttab/do-secundo-guest-authentication'
import { useIntlProps } from 'banquet-runtime-modules'
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { queryClient } from '../../App'
import { Availability } from '../../api/availabilities/getAvailabilities'
import { Reservation } from '../../api/bookings/getBooking'
import { GetBookingQueryKey } from '../../api/bookings/useGetBooking'
import { useUpdateReservation } from '../../api/bookings/useUpdateReservation'
import { useFindReserveContext } from '../../contexts/find-reserve-context'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useReservationInfo } from '../../hooks/useReservationInfo/useReservationInfo'
import { formatAvailabilities } from '../../utils/formatAvailabilities'
import { ReservationDetails } from '../ReservationBookModal/ReservationDetails'
import { ReservationError } from '../ReservationBookModal/ReservationError'
import { DiningAreaSelector } from './DiningAreaSelector'
import { ModifyDatePicker } from './ModifyDatePicker'
import { ModifyTimePicker } from './ModifyTimePicker'
import { PartySizeSelector } from './PartySizeSelector'
import { SpecialOccasionSelector } from './SpecialOccasionSelector'
import { SpecialRequestInputField } from './SpecialRequestInputField'
import {
  ReservationUpdateFormProps,
  ReservationUpdateModalProps
} from './models'
import { useUpdateAvailabilitiesRequest } from './useUpdateAvailabilitiesRequest'
import { track } from '@toasttab/do-secundo-analytics'
import { AnalyticsEvents } from '../../utils/analyticsEvents'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'

const getInitialValues = (booking: Reservation) => {
  return {
    firstName: booking.mainGuest.firstName,
    lastName: booking.mainGuest.lastName,
    phoneNumber: booking.mainGuest.phoneNumber ?? '',
    email: booking.mainGuest.email ?? '',
    specialRequests: booking.bookingNotes ?? '',
    specialOccasion: booking.specialOccasion ?? '',
    bookingNotes: booking.bookingNotes ?? '',
    datetime: booking.expectedStartTime,
    date: DateTime.fromISO(booking.expectedStartTime),
    serviceAreaGroupName: booking.serviceAreaGroup.name,
    serviceAreaGroupGuid: booking.serviceAreaGroup.guid,
    partySize: booking.partySize
  }
}

export const ReservationUpdateModal = ({
  booking,
  restaurant,
  isVisible,
  setIsVisible,
  setPreviousState,
  setUpdateSuccessful
}: ReservationUpdateModalProps) => {
  const { showSuccessSnackBar, showErrorSnackBar } = useSnackBar()
  const { language: locale } = useIntlProps()
  const { isAuthenticated } = useAuth()
  const updateReservation = useUpdateReservation(isAuthenticated)

  const { hasDateChanged, setHasDateChanged } = useFindReserveContext()
  const isMobile = useIsMobile()

  const newAvailabilities = useUpdateAvailabilitiesRequest()

  const [reservationError, setReservationError] = useState('')
  const [availabilities, setAvailabilities] = useState<Availability[]>([])
  const { serviceAreaGroupNameToTimes } = formatAvailabilities(
    availabilities,
    booking && !booking.depositAmount
  )
  const serviceAreaGroupNameToGuid = new Map<string, string>()
  restaurant.serviceAreaGroups
    .filter((sag) => serviceAreaGroupNameToTimes.get(sag.name)?.size)
    .forEach((serviceAreaGroup) => {
      serviceAreaGroupNameToGuid.set(
        serviceAreaGroup.name,
        serviceAreaGroup.guid
      )
    })

  const initialValues = getInitialValues(booking)

  const handleTimeChange = (
    selectedDate: Date,
    oldDate: string,
    setter: Function
  ) => {
    const oldDateTime = DateTime.fromISO(oldDate)
    const newDate = DateTime.fromJSDate(selectedDate)
    const newDateTime = oldDateTime.isValid
      ? newDate.set({
          hour: oldDateTime.hour,
          minute: oldDateTime.minute
        })
      : newDate
    setter('date', newDateTime)
    setter('datetime', newDateTime.setZone('utc').toISO())
  }

  const onSubmit = async (
    values: ReservationUpdateFormProps,
    formikHelpers: FormikHelpers<ReservationUpdateFormProps>
  ) => {
    try {
      await updateReservation.mutateAsync(
        {
          bookingId: booking.guid,
          params: {
            restaurantGuid: restaurant.guid,
            partySize: values.partySize,
            expectedStartTime: values.datetime,
            specialOccasion: values.specialOccasion || 'NONE',
            bookingNotes: values.specialRequests || '',
            externalServiceArea: values.serviceAreaGroupName!!,
            requestedServiceAreaGroups: values.serviceAreaGroupGuid
              ? [values.serviceAreaGroupGuid]
              : []
          }
        },
        {
          onSuccess: (resp) => {
            const codes = resp?.errorCodes
            if (codes?.length === 0) {
              setPreviousState?.({
                ...initialValues,
                serviceAreaGroup: {
                  name: initialValues.serviceAreaGroupName,
                  guid: initialValues.serviceAreaGroupGuid
                },
                expectedStartTime: initialValues.datetime
              })

              setUpdateSuccessful?.(true)
              queryClient.invalidateQueries(GetBookingQueryKey)
              formikHelpers.setSubmitting(false)
              setIsVisible(false)
              showSuccessSnackBar('Reservation successfully modified.')
              track(AnalyticsEvents.RESERVATION_MODIFIED)
            }

            if (codes?.includes('booking.web.overlapping')) {
              setReservationError('overlapping')
            } else if (codes?.includes('booking.web.genError')) {
              setReservationError('generic')
            }
          },
          onError: () => setReservationError('unexpected')
        }
      )
    } catch (err) {
      showErrorSnackBar(
        'There was an issue updating this reservation. Please try again.'
      )
    }
  }

  let formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: Yup.object({ datetime: Yup.string().required() }),
    enableReinitialize: true
  })

  const { hoursData, isAfterHours, isBlocked, isRestaurantClosed } =
    useReservationInfo(restaurant, formik.values.date, () => {
      setHasDateChanged?.(false)
    })

  useEffect(() => {
    newAvailabilities.mutate(
      {
        restaurantGuid: restaurant.guid,
        startTime: DateTime.fromISO(formik.values.date.toISO())
          .set({ hour: restaurant.closeOutHour, minute: 0 })
          .toISO(),
        endTime: DateTime.fromISO(formik.values.date.toISO())
          .set({ hour: restaurant.closeOutHour, minute: 0 })
          .plus({ hours: 23, minutes: 59 })
          .toISO(),
        partySize: formik.values.partySize,
        enabled: true,
        specifiedBookableGuid: booking.bookable?.guid || ''
      },
      {
        onSuccess: (response) => {
          setAvailabilities(response)
          const originalBookingDate = DateTime.fromISO(
            booking.expectedStartTime
          )
          formik.setFieldTouched('datetime', false)

          if (
            originalBookingDate.hasSame(formik.values.date, 'day') &&
            originalBookingDate.hasSame(formik.values.date, 'month') &&
            !response
              .map((availability) => availability.datetime)
              .includes(booking.expectedStartTime)
          ) {
            setReservationError('oldTimeUnavailable')
          } else {
            setReservationError('')
          }
        }
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isVisible,
    hasDateChanged,
    hoursData?.data,
    restaurant.bookingMinHoursInAdvance,
    restaurant.timezone,
    formik.values.partySize,
    formik.values.serviceAreaGroupName,
    formik.values.date
  ])

  if (!hoursData) {
    return null
  }

  const isDateTimeInvalid =
    isRestaurantClosed || isBlocked || hoursData.isError || isAfterHours

  return (
    <FormikProvider value={formik}>
      <Modal
        isOpen={isVisible}
        parentSelector={() =>
          document.getElementById('banquetPortalsContainer')
        }
        overflowBehavior='body'
        onRequestClose={() => {
          setIsVisible(false)
          formik.resetForm()
          setAvailabilities([])
          setReservationError('')
        }}
        size='xxl'
      >
        {' '}
        <Modal.Header>Modify reservation</Modal.Header>
        <Modal.Body className='mt-2'>
          <div className='flex flex-col text-default mb-2'>
            <div>Updating reservation for:</div>
            <ReservationDetails restaurant={restaurant} booking={booking} />
            <div className='border-b pt-4 mb-2' />
          </div>

          <div className='flex flex-col gap-4 items-center text-default'>
            {reservationError === 'generic' && (
              <ReservationError>
                This reservation is no longer available.
              </ReservationError>
            )}
            {reservationError === 'unexpected' && (
              <Alert title='' variant='error'>
                Unfortunately, the highlighted selection below is no longer
                available, please select another option.
              </Alert>
            )}
            {reservationError === 'overlapping' && (
              <Alert showIcon variant='error'>
                Reservation could not be made because it overlaps with another
                existing reservation you have made. Please edit your reservation
                date or time.
              </Alert>
            )}
            {reservationError === 'deposit' && (
              <ReservationError>
                Unable to create a reservation.
              </ReservationError>
            )}
            <Form
              id='modify'
              onSubmit={formik.handleSubmit}
              className='w-full pb-2 flex flex-col gap-3'
            >
              <PartySizeSelector
                isDateTimeInvalid={isDateTimeInvalid}
                formik={formik}
                disabled={
                  hoursData.isLoading || isRestaurantClosed || isAfterHours
                }
              />
              <DiningAreaSelector
                formik={formik}
                serviceAreaGroupNameToGuid={serviceAreaGroupNameToGuid}
                disabled={
                  hoursData.isLoading || isRestaurantClosed || isAfterHours
                }
              />
              <ModifyDatePicker
                formik={formik}
                handleTimeChange={handleTimeChange}
                setHasDateChanged={setHasDateChanged!}
                disabled={hoursData.isLoading}
                locale={locale}
              />
              {newAvailabilities.isLoading ? (
                <div>
                  <MerryGoRound className='m-auto' size='md' />
                  <div className='sr-only' role='status'>
                    Loading availabilities...
                  </div>
                </div>
              ) : (
                <ModifyTimePicker
                  serviceAreaGroupNameToTimes={serviceAreaGroupNameToTimes}
                  formik={formik}
                  isMobile={isMobile}
                  restaurant={restaurant}
                  setReservationError={setReservationError}
                />
              )}
              <SpecialOccasionSelector isMobile={isMobile} formik={formik} />
              <SpecialRequestInputField formik={formik} />
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <SubmitButton
            form='modify'
            size='base'
            className='w-full'
            disabled={
              formik.isSubmitting ||
              !(formik.isValid && formik.dirty) ||
              newAvailabilities.isLoading ||
              availabilities.length <= 0 ||
              reservationError !== ''
            }
          >
            <span className='flex gap-2 items-center'>
              {updateReservation.isLoading && (
                <AutorenewIcon
                  className='animate-spin'
                  accessibility='decorative'
                />
              )}
              Modify reservation
            </span>
          </SubmitButton>{' '}
        </Modal.Footer>
      </Modal>
    </FormikProvider>
  )
}
