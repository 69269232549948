import { Button } from '@toasttab/buffet-pui-buttons'
import React from 'react'

type LoginButtonProps = {
  setLoginStepCount: React.Dispatch<React.SetStateAction<number>>
  setStepCount: React.Dispatch<React.SetStateAction<number>>
}

export const LoginButton = ({
  setLoginStepCount,
  setStepCount
}: LoginButtonProps) => {
  return (
    <div className='pb-4'>
      <Button
        testId='express-booking-button'
        size='lg'
        className='w-full py-2.5 gap-3'
        onClick={() => {
          setStepCount(2)
          setLoginStepCount(2)
        }}
        autoFocus
      >
        <div>Continue with express booking</div>
      </Button>
    </div>
  )
}
