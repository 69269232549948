import {
  GetAllBookingsForGuestProfileQueryKey,
  GuestOverviewReservation
} from '../../api/guest/useGetAllBookingsForGuestProfile'
import { useFetchRestaurantV2 } from '../CreditCardForm/api/useFetchRestaurantV2'
import { Image } from '@toasttab/buffet-pui-image'
import { CalendarTodayIcon, GuestIcon } from '@toasttab/buffet-pui-icons'
import { DateTime } from 'luxon'
import React from 'react'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import { useGetRestaurant } from '../../api/restaurant/useGetRestaurant'
import { useGetBooking } from '../../api/bookings/useGetBooking'
import { ReservationUpdateModal } from '../ReservationUpdateModal/ReservationUpdateModal'
import { BookingStatus } from '../../api/bookings/getBooking'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { queryClient } from '../../App'

type BookingCardProps = {
  booking: GuestOverviewReservation
  past: boolean
  openUpdateModal: boolean
  setOpenUpdateModal: React.Dispatch<React.SetStateAction<boolean>>
  key: number
}

export const BookingCard = ({
  booking,
  past,
  openUpdateModal,
  setOpenUpdateModal,
  key
}: BookingCardProps) => {
  const { data: fetchRestaurantV2Data, isLoading: isFetchRestaurantV2Loading } =
    useFetchRestaurantV2(booking.restaurantGuid)
  const { data: restaurant, isLoading: isGetRestaurantLoading } =
    useGetRestaurant(booking.restaurantGuid, {
      slotTime: null
    })
  const { data: bookingData, refetch } = useGetBooking(booking.guid, false)
  const navigate = useNavigate()
  const [updateSuccessful, setUpdateSuccessful] = React.useState(false)
  const fallbackProfilePicUrl =
    'https://storage.googleapis.com/nv1-booking-images/Toast Placeholder Image.png'

  const getLogoUrl = () => {
    let url = fetchRestaurantV2Data?.logoUrls?.xs
    if (url) {
      url = url.replace('dks3o9tl87y4h', 'd2s742iet3d3t1')
      return url
    }
  }

  const date = restaurant
    ? DateTime.fromISO(booking.expectedStartTime)
        .setZone(restaurant.timezone)
        .toLocaleString({
          month: 'short',
          day: 'numeric',
          year: 'numeric'
        })
    : ''
  const time = restaurant
    ? DateTime.fromISO(booking.expectedStartTime)
        .setZone(restaurant.timezone)
        .toFormat('h:mm a')
    : ''

  if (updateSuccessful) {
    setUpdateSuccessful(false)
    queryClient.invalidateQueries(GetAllBookingsForGuestProfileQueryKey)
  }

  const redirect = React.useCallback(() => {
    const findTimeUrl = `/${booking.restaurantShortName}/findTime`
    navigate(findTimeUrl, { replace: true })
  }, [navigate, booking.restaurantShortName])

  return (
    <div className='flex flex-col gap-4' key={key}>
      <div className='flex flex-col md:flex-row w-full rounded-[12px] shadow-xl bg-white justify-between'>
        <Link
          key={key}
          to={`/${booking.restaurantShortName}/bookings/${booking.guid}`}
        >
          <div className='flex flex-row px-4 pt-2 md:pb-2 items-center md:w-full'>
            <Image
              src={getLogoUrl() || fallbackProfilePicUrl}
              alt=''
              fit='contain'
              className='rounded-[12px] max-h-16 max-w-16 border-default'
              containerClassName='w-16 h-16'
              placeholder={<></>}
            />
            <div className='py-4 ml-4'>
              {isFetchRestaurantV2Loading && <Skeleton className='h-[30px]' />}
              <h3 className='text-md font-semibold mb-1'>{restaurant?.name}</h3>
              {booking.bookingStatus === BookingStatus.R_CANCELLED && (
                <div className='flex items-center justify-start font-medium type-overline type-caption truncate bg-darken-8 mb-1 w-fit py-0.5 px-2.5 rounded-md'>
                  Canceled
                </div>
              )}
              {booking.requestedServiceAreaGroups[0] && (
                <p className='text-secondary type-subhead mb-1'>
                  Reserved for {booking.requestedServiceAreaGroups[0].name}
                </p>
              )}
              <div className='flex flex-row text-secondary type-subhead'>
                <span className='flex gap-1 mr-4 items-center'>
                  <GuestIcon accessibility='decorative' size='xs' />
                  {booking.partySize}
                </span>
                <span className='flex gap-1 items-center'>
                  <CalendarTodayIcon accessibility='decorative' size='xs' />
                  {isGetRestaurantLoading && <Skeleton className='h-[30px]' />}
                  {restaurant && (
                    <>
                      {date} @ {time}
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
        </Link>
        <div className='flex w-full md:w-fit items-center px-4 pb-4 md:pb-0'>
          {past ? (
            <button
              className='w-full md:w-fit flex h-8 md:h-10 type-subhead md:type-default py-2 px-3 md:px-4 items-center justify-center rounded-[9999px] bg-white font-semibold border border-gray-50'
              onClick={() => {
                redirect()
              }}
            >
              Rebook
            </button>
          ) : (
            <button
              className='w-full md:w-fit flex h-8 md:h-10 type-subhead md:type-default py-2 px-3 md:px-4 items-center justify-center rounded-[9999px] bg-white font-semibold border border-gray-50'
              onClick={() => {
                refetch()
                setOpenUpdateModal(true)
              }}
            >
              Modify
            </button>
          )}
        </div>
        {bookingData && restaurant && (
          <ReservationUpdateModal
            booking={bookingData}
            restaurant={restaurant}
            isVisible={openUpdateModal}
            setIsVisible={setOpenUpdateModal}
            setUpdateSuccessful={setUpdateSuccessful}
          />
        )}
      </div>
    </div>
  )
}
