import React, { useEffect } from 'react'
import { DateTime } from 'luxon'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useGetExperiences } from '../../api/experiences/useGetExperiences'
import { ExperiencesFrame } from '../Frame/ExperiencesFrame'
import { FullExperienceCard } from './FullExperienceCard'
import { Helmet } from 'react-helmet-async'

export const AllUpcomingExperiencesView = ({
  restaurant
}: {
  restaurant: RestaurantInfo
}) => {
  const currentDateTime = DateTime.now()
    .startOf('day')
    .toUTC()
    .toString()
    .replace('Z', '+00:00')
  const { data: experiences } = useGetExperiences(
    restaurant.guid,
    currentDateTime
  )

  useEffect(() => {
    // Scroll to the top when the page is navigated to
    window.scrollTo(0, 0)
  }, [])

  if (!experiences) {
    return null
  }

  return (
    <ExperiencesFrame restaurant={restaurant}>
      <Helmet>
        <title>
          {restaurant.name} - Experiences - {restaurant.city},{' '}
          {restaurant.state} | Toast Tables
        </title>
      </Helmet>
      <ul>
        {experiences.map((experience, index) => (
          <li key={experience.guid}>
            {index !== 0 && <div className='border-b' />}
            <FullExperienceCard
              restaurant={restaurant}
              experience={experience}
            />
          </li>
        ))}
      </ul>
    </ExperiencesFrame>
  )
}
