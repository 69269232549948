import React from 'react'
import { useFormikContext } from 'formik'
import { useNavigate } from 'react-router'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { useFindReserveContext } from '../../contexts/find-reserve-context'
import CreditCardForm from '../CreditCardForm/CreditCardForm'
import { useCreditCardForm } from '../CreditCardForm/CreditCardFormProvider'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { ReservationDetailEntryViewFormValues } from '../ReservationDetailEntryView/models'
import { DepositStrategyType } from '../../api/deposits/model'
import { useGetBooking } from '../../api/bookings/useGetBooking'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { DepositRefundText } from '../DepositRefundText/DepositRefundText'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import { NewLineFormatter } from '../NewLineFormatter/NewLineFormatter'
import { BookingCreationLegalText } from '../BookingCreationLegalText'
import { useAuth } from '@toasttab/do-secundo-guest-authentication'
import { useRestaurantContext } from '../../contexts/RestaurantContext'

const buttonText = 'Complete booking'

type CompleteBookingStepProps = {
  bookingGuid: string
  restaurant: RestaurantInfo
}

export const CompleteBookingStep = ({
  bookingGuid,
  restaurant
}: CompleteBookingStepProps) => {
  const { isAuthenticated } = useAuth()
  const formatCurrency = useFormatCurrency()
  const { selectedAvailability } = useFindReserveContext()
  const { paymentError } = useCreditCardForm()
  const navigate = useNavigate()
  const formik = useFormikContext<ReservationDetailEntryViewFormValues>()
  const { data: booking } = useGetBooking(bookingGuid)
  const { shortUrl } = useRestaurantContext()

  if (!booking) {
    return (
      <div>
        <MerryGoRound />
        <div className='sr-only' role='status'>
          Creating reservation...
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='flex flex-col gap-2'>
        <div className='flex flex-col gap-1'>
          {selectedAvailability?.deposit?.depositPolicy && (
            <>
              <div className='text-secondary'>
                <NewLineFormatter
                  text={selectedAvailability.deposit.depositPolicy}
                />
              </div>
              <div className='border-b pt-4 mb-4' />
            </>
          )}
          <h4 className='type-large font-semibold'>Reservation Deposit</h4>
          {selectedAvailability?.deposit?.strategy.type ===
            DepositStrategyType.ByPartySize && (
            <p className='text-secondary'>
              {formatCurrency(
                selectedAvailability.deposit.strategy.amountPerGuest || 0
              )}{' '}
              deposit per person.
            </p>
          )}
          {selectedAvailability?.deposit?.strategy.type ===
            DepositStrategyType.ByBooking && (
            <p className='text-secondary'>
              {formatCurrency(
                selectedAvailability.deposit.strategy.actualAmount || 0
              )}{' '}
              deposit per booking.
            </p>
          )}
        </div>
        <div className='flex justify-between'>
          <p>
            Reservation
            {selectedAvailability?.deposit?.strategy.type ===
            DepositStrategyType.ByPartySize
              ? ` x ${selectedAvailability.partySize}`
              : ' x 1'}
          </p>
          <p>
            {formatCurrency(
              selectedAvailability?.deposit?.strategy.actualAmount || 0
            )}
          </p>
        </div>
      </div>
      <div className='border-b pt-2 mb-2' />
      <div className='flex flex-col gap-2'>
        <div className='flex justify-between type-headline-5 font-bold'>
          <p>Amount Due</p>
          <p>
            {formatCurrency(
              selectedAvailability?.deposit?.strategy.actualAmount || 0,
              { trailingZeros: true }
            )}
          </p>
        </div>
        {selectedAvailability?.deposit?.cancellationRefundableTimeframe !=
          null && (
          <DepositRefundText
            timeFrame={
              selectedAvailability.deposit.cancellationRefundableTimeframe
            }
          />
        )}
        {paymentError && (
          <Alert variant='error' title='Payment Unsuccessful' className='my-2'>
            We were unable to run the payment method you provided. Please
            contact your bank or try using a different card.
          </Alert>
        )}
        <CreditCardForm
          email={formik.values.email}
          restaurant={restaurant}
          onSuccess={() => {
            navigate(`/${shortUrl}/bookings/${booking.guid}`)
          }}
          checkGuid={booking.depositCheckId || ''}
          orderGuid={booking.depositOrderId || ''}
        />
        <div className='text-secondary type-subhead'>
          <BookingCreationLegalText
            isAuthenticated={isAuthenticated}
            restaurant={restaurant}
            buttonText={buttonText}
          />
        </div>
      </div>
    </>
  )
}
