import { useMemo } from 'react'
import { DateTime } from 'luxon'
import { SelectOption } from '@toasttab/buffet-pui-select'
import { useGetDayInfo } from '../../api/restaurant/useGetDayInfo'
import {
  DEFAULT_MAX_PARTY_SIZE,
  DEFAULT_MIN_PARTY_SIZE
} from '@local/constants'

export const usePartySizes = (date: DateTime): SelectOption<number>[] => {
  const { restaurantGuid } = window.TT_GLOBALS
  const { data: dayInfo } = useGetDayInfo(restaurantGuid, date)

  return useMemo(() => {
    const arr: SelectOption<number>[] = []

    const minPartySize = dayInfo?.minPartySize ?? DEFAULT_MIN_PARTY_SIZE
    const maxPartySize = dayInfo?.maxPartySize ?? DEFAULT_MAX_PARTY_SIZE

    for (let i = minPartySize; i <= maxPartySize; i++) {
      arr.push({
        label: `${i}`,
        value: i
      })
    }

    return arr
  }, [dayInfo?.maxPartySize, dayInfo?.minPartySize])
}
