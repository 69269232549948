import { DateTime } from 'luxon'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useGetDayInfo } from '../../api/restaurant/useGetDayInfo'
import { getSpecialHours } from '../../components/ReservationTimeSelectionViewForm/helpers'
import { calculateTimes } from '../../utils/calculateTimes'
import { useGetOnlineReservationHours } from '../../api/availabilities/onlineReservationHours/useGetOnlineReservationHours'
import { useFindReserveContext } from '../../contexts/find-reserve-context'
import { OnlineReservationHoursInterval } from '../../api/availabilities/onlineReservationHours/getOnlineReservationHours'
import { useIntlProps } from 'banquet-runtime-modules'
import { useGetExperienceFromSlug } from '../useGetExperienceFromSlug'
import {
  getIsAfterHours,
  getIsBlocked,
  getIsRestaurantClosed
} from './utilities'
import { useParams } from 'react-router-dom'
import { useGetBooking } from '../../api/bookings/useGetBooking'

export const useReservationInfo = (
  restaurant: RestaurantInfo,
  date: DateTime,
  onHoursSuccess?: (data: OnlineReservationHoursInterval[]) => void
) => {
  const { experience } = useGetExperienceFromSlug()
  const isExperience = Boolean(experience)
  const { hasDateChanged } = useFindReserveContext()
  const { bookingGuid = '' } = useParams()
  const { data: bookingData } = useGetBooking(bookingGuid, false)
  const experienceGuid = bookingData?.bookable?.guid || experience?.guid || ''

  const { data: dayInfo } = useGetDayInfo(restaurant.guid, date)
  const hoursData = useGetOnlineReservationHours({
    restaurantGuid: restaurant.guid,
    date,
    enabled: hasDateChanged && date.isValid,
    onSuccess: (data) => {
      onHoursSuccess?.(data)
    },
    bookableGuid: experienceGuid
  })
  const { language: locale } = useIntlProps()

  if (!dayInfo && !hoursData) {
    return {}
  }

  const hoursOptions = calculateTimes(hoursData.data, restaurant, date, locale)
  const isBlocked = getIsBlocked({ isExperience, dayInfo })
  const isRestaurantClosed = getIsRestaurantClosed({
    isExperience,
    isBlocked,
    hoursData
  })
  const isAfterHours = getIsAfterHours({
    isExperience,
    isBlocked,
    isRestaurantClosed,
    hoursOptions
  })

  const specialHours = getSpecialHours(hoursData?.data)

  const blockedReason =
    dayInfo?.blockedReason ||
    'The restaurant is not accepting online reservations for this date.'

  const scheduleMemoName = dayInfo?.scheduleDescription?.name || ''
  const scheduleMemoDescription =
    dayInfo?.scheduleDescription?.description || ''

  const specialDateName = (scheduleMemoName || dayInfo?.specialDateName) ?? ''
  const specialDateDescription =
    (scheduleMemoDescription || dayInfo?.specialDateDescription) ?? ''

  const hasSpecialDateInfo =
    !isExperience &&
    !isRestaurantClosed &&
    !isBlocked &&
    dayInfo !== undefined &&
    (specialDateName || specialDateDescription)

  const shouldShowSpecialHours = !isBlocked && specialHours.length > 0

  const shouldShowInfoPanel =
    isBlocked ||
    hasSpecialDateInfo ||
    isRestaurantClosed ||
    isAfterHours ||
    shouldShowSpecialHours

  return {
    blockedReason,
    dayInfo,
    hasSpecialDateInfo,
    hoursData,
    hoursOptions,
    isAfterHours,
    isBlocked,
    isRestaurantClosed,
    shouldShowInfoPanel,
    shouldShowSpecialHours,
    specialDateDescription,
    specialDateName,
    specialHours
  }
}
