import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { Guest } from '../../api/guest/useGetGuestProfile'
import { formatPhone } from '@toasttab/buffet-pui-phone-utilities'
import React from 'react'
import { parsePhoneNumber } from '../../utils/parsePhoneNumber'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'

type GuestContactProps = {
  guest: Guest | undefined
  restaurant: RestaurantInfo
}

export const GuestContact = ({ guest, restaurant }: GuestContactProps) => {
  if (!guest) {
    return (
      <div className='flex justify-center items-center w-full bg-gray-0 rounded-lg h-24'>
        <MerryGoRound />
        <div className='sr-only' role='status'>
          Loading guest account information
        </div>
      </div>
    )
  }
  const { phone, email, firstName, lastName } = guest.contact
  return (
    <div className='w-full gap-x-0 lg:gap-x-4 gap-y-4 bg-gray-0 rounded-lg'>
      <div className='p-3'>
        <div className='font-medium'>
          {firstName} {lastName}
        </div>
        <div>
          {formatPhone(
            parsePhoneNumber(phone),
            restaurant.country,
            'international'
          )}
        </div>
        <div>{email}</div>
      </div>
    </div>
  )
}
