import React from 'react'
import { DateTime } from 'luxon'
import { SparkleIcon } from '@toasttab/buffet-pui-icons'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { Helmet } from 'react-helmet-async'
import { useReservationInfo } from '../../hooks/useReservationInfo/useReservationInfo'
import { Frame } from '../Frame/Frame'
import { NewLineFormatter } from '../NewLineFormatter/NewLineFormatter'
import { UpdateSearchButton } from '../UpdateSearchButton/UpdateSearchButton'
import { usePartySize } from '../../hooks/usePartySize'
import { ExpiredBookingAlert } from '../ExpiredBookingAlert/ExpiredBookingAlert'
import { ReservationContentWrapper } from '../ReservationContentWrapper/ReservationContentWrapper'
import { AvailabilitiesSection } from '../AvailabilitiesSection/AvailabilitiesSection'
import { LimitedUpcomingExperiencesView } from '../ExperienceView/LimitedUpcomingExperiencesView'
import { useSetupReservationParams } from '../../hooks/useSetupReservationParams'
import { useGetDateTimeParam } from '../../hooks/useGetDateTimeParam/useGetDateTimeParam'
import { parseEpochOrIsoToUtc } from '../../utils/parseEpochOrIsoToUtc'

type ReservationDetailEntryViewProps = {
  restaurant: RestaurantInfo
}

export const ReservationDetailEntryView = ({
  restaurant
}: ReservationDetailEntryViewProps) => {
  const { partySize } = usePartySize()
  const getDateTimeParam = useGetDateTimeParam()

  const dateTimeString = getDateTimeParam('dateTime')
  const utcDateTime = parseEpochOrIsoToUtc(dateTimeString)
  const isDateTimeValid = utcDateTime.isValid

  const {
    blockedReason,
    hasSpecialDateInfo,
    isAfterHours,
    isBlocked,
    isRestaurantClosed,
    shouldShowInfoPanel,
    shouldShowSpecialHours,
    specialDateDescription,
    specialDateName,
    specialHours
  } = useReservationInfo(restaurant, DateTime.fromISO(dateTimeString))

  useSetupReservationParams(restaurant)

  if (!partySize || !isDateTimeValid) {
    return (
      <Frame restaurant={restaurant}>
        <MerryGoRound />
        <div className='sr-only' role='status'>
          Loading availabilities...
        </div>
      </Frame>
    )
  }

  return (
    <Frame restaurant={restaurant}>
      <Helmet>
        <title>
          {restaurant.name} - {restaurant.city}, {restaurant.state} | Toast
          Tables
        </title>
      </Helmet>
      <ExpiredBookingAlert />
      <ReservationContentWrapper restaurant={restaurant}>
        {!restaurant.onlineReservationsEnabled ? (
          <div className='type-default text-left font-semibold md:mt-4'>
            {restaurant.name} is not accepting reservations at the moment.{' '}
          </div>
        ) : (
          <>
            <div className='flex flex-col gap-4'>
              <h1 className='type-large text-dark-gray font-semibold md:mt-4'>
                Book a reservation
              </h1>
              <UpdateSearchButton restaurant={restaurant} />
              {shouldShowInfoPanel && (
                <div className='flex flex-col gap-1'>
                  {isBlocked && (
                    <div className='text-default font-normal type-default'>
                      {blockedReason}
                    </div>
                  )}

                  {shouldShowSpecialHours && (
                    <div className='text-default font-normal type-default'>
                      Special reservation hours for this date:
                      {specialHours.map(({ start, end }, index) => (
                        <React.Fragment key={`${start}${end}`}>
                          {index !== 0 && ' and '}
                          <span className='text-brand-75'>
                            {' '}
                            {start}
                            {start !== end && ` - ${end}`}
                          </span>
                        </React.Fragment>
                      ))}
                    </div>
                  )}

                  {isRestaurantClosed && (
                    <div className='text-default font-normal type-default'>
                      {restaurant.name} is not accepting online reservations for
                      this date.
                    </div>
                  )}

                  {isAfterHours && (
                    <div className='text-default font-normal type-default'>
                      {restaurant.name} is not accepting reservations for this
                      time. Please call, or choose another date.
                    </div>
                  )}

                  {hasSpecialDateInfo && specialDateName !== '' && (
                    <div className='flex flex-row gap-1.5 items-center'>
                      <SparkleIcon size='sm' accessibility='decorative' />
                      <div className='text-default font-semibold type-default align-center'>
                        {specialDateName}
                      </div>
                    </div>
                  )}

                  {hasSpecialDateInfo && specialDateDescription !== '' && (
                    <div className='text-default font-normal type-default'>
                      <NewLineFormatter text={specialDateDescription} />
                    </div>
                  )}
                </div>
              )}
              <div className='flex flex-col gap-2'>
                <AvailabilitiesSection restaurant={restaurant} />
                <LimitedUpcomingExperiencesView restaurant={restaurant} />
              </div>
            </div>
          </>
        )}
      </ReservationContentWrapper>
    </Frame>
  )
}
