import axios from 'axios'
import { NvResponse, RequestName, RequestURL } from '../baseModels'
import { createG2Request } from '../createRequest'
import { useQuery } from 'react-query'
import { useBanquetProps } from 'banquet-runtime-modules'
import { Experience } from './model'

export const GetUpcomingExperiencesQueryKey = 'upcomingExperiences'

const getExperiences = async (
  restaurantGuid: string,
  startDate: string
): Promise<Experience[]> =>
  axios
    .request<NvResponse<Experience>>(
      createG2Request(
        RequestURL[RequestName.GET_UPCOMING_EXPERIENCES],
        { 'Toast-Restaurant-External-ID': restaurantGuid },
        undefined,
        { startDate }
      )
    )
    .then((response) => response.data.results)

export const useGetExperiences = (
  restaurantGuid: string,
  startDate: string
) => {
  const { featureFlags } = useBanquetProps()
  const isExperiencesEnabled = Boolean(featureFlags?.['nv1-experiences'])
  return useQuery(
    [GetUpcomingExperiencesQueryKey, restaurantGuid, startDate],
    () => getExperiences(restaurantGuid, startDate),
    {
      enabled: Boolean(isExperiencesEnabled && restaurantGuid && startDate)
    }
  )
}
