import axios from 'axios'
import { NvResponse, RequestName, RequestURL } from '../baseModels'
import { createG2Request } from '../createRequest'
import { useQuery } from 'react-query'
import { useBanquetProps } from 'banquet-runtime-modules'
import { Experience } from './model'
import { useParams } from 'react-router-dom'

export const GetExperienceQueryKey = 'experience'

const getExperience = async (
  experienceSlug: string,
  restaurantGuid: string
): Promise<Experience> =>
  axios
    .request<NvResponse<Experience>>(
      createG2Request(
        RequestURL[RequestName.GET_EXPERIENCE],
        { 'Toast-Restaurant-External-ID': restaurantGuid },
        { experienceSlug },
        undefined
      )
    )
    .then((response) => response.data.results[0])

export const useGetExperience = () => {
  const { restaurantGuid } = window.TT_GLOBALS
  const { featureFlags } = useBanquetProps()
  const isExperiencesEnabled = Boolean(featureFlags?.['nv1-experiences'])
  const { experienceSlug = '' } = useParams()

  const { data: experience, isLoading } = useQuery(
    [GetExperienceQueryKey, experienceSlug],
    () => getExperience(experienceSlug, restaurantGuid),
    {
      enabled: Boolean(isExperiencesEnabled && experienceSlug),
      retry: false
    }
  )

  return {
    experience,
    isLoading
  }
}
