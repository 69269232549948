import { DateTime } from 'luxon'
import { Experience, ExperienceHours } from '../api/experiences/model'
import { sortDates } from './sortDates'

export const getNextEnabledDateTime = (
  experience: Experience,
  restaurantTimeZone: string
) => {
  // Retrieve the time slot size from the experience (in minutes)
  const timeSlotSize = experience.shifts[0].timeSlotSize
  // Get current time in the restaurant's timezone.
  const nowRaw = DateTime.now().setZone(restaurantTimeZone)
  // Round up now to the next time slot.
  const remainder = nowRaw.minute % timeSlotSize
  const now =
    remainder === 0 && nowRaw.second === 0 && nowRaw.millisecond === 0
      ? nowRaw
      : nowRaw.plus({ minutes: timeSlotSize - remainder }).startOf('minute')

  // Create a candidate source array.
  let candidateDays: DateTime[] = []

  if (experience.alwaysAvailable) {
    // With alwaysAvailable true, every day (for the next 7 days) is a candidate.
    for (let i = 0; i < 7; i++) {
      candidateDays.push(now.plus({ days: i }))
    }
  } else {
    // When not always available, we only consider datesActive.
    candidateDays = sortDates(experience.datesActive).map((dateString) =>
      DateTime.fromISO(dateString, { zone: restaurantTimeZone })
    )
  }

  // Loop through candidate days, checking if the day-of-week is enabled and if it fits the time criteria.
  for (const day of candidateDays) {
    const dayOfWeek = day
      .toFormat('cccc')
      .toLowerCase() as keyof ExperienceHours
    const dayHours = experience.shifts[0].hours[dayOfWeek]
    // Skip if this day's shift isn't enabled.
    if (!dayHours.enabled) continue

    const candidate = processCandidate(now, day, dayHours, restaurantTimeZone)
    if (candidate) {
      return candidate
    }
  }

  // Fallback: if no candidate is found, return now.
  return now
}

// Helper: Given a day and its hours, compute the candidate start and end DateTimes in restaurantTimeZone.
const getCandidateForDay = (
  day: DateTime,
  hours: { start: string; end: string },
  restaurantTimeZone: string
) => {
  const candidateStart = DateTime.fromISO(`${day.toISODate()}T${hours.start}`, {
    zone: restaurantTimeZone
  })
  const dayEndCandidate = DateTime.fromISO(`${day.toISODate()}T${hours.end}`, {
    zone: restaurantTimeZone
  })
  // If the end time is before the start time, the shift crosses midnight.
  const candidateEnd =
    dayEndCandidate < candidateStart
      ? DateTime.fromISO(`${day.plus({ days: 1 }).toISODate()}T${hours.end}`, {
          zone: restaurantTimeZone
        })
      : dayEndCandidate
  return { candidateStart, candidateEnd }
}

// A function to check if a candidate day is valid and return the proper DateTime.
const processCandidate = (
  now: DateTime,
  day: DateTime,
  hours: { start: string; end: string },
  restaurantTimeZone: string
) => {
  const { candidateStart, candidateEnd } = getCandidateForDay(
    day,
    hours,
    restaurantTimeZone
  )
  // For today: if now is before the shift start, return the shift start.
  // If now is within the shift window, return now.
  if (day.hasSame(now, 'day')) {
    if (now < candidateStart) {
      return candidateStart
    }
    if (now <= candidateEnd) {
      return now
    }
    // Otherwise, today's shift has ended.
    return null
  }
  // For future days, always return the candidate start time.
  if (day > now) {
    return candidateStart
  }
  return null
}
