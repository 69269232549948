import { useNavigate } from 'react-router-dom'
import { isPastUtcDate } from '../../utils/isPastUtcDate'
import * as React from 'react'
import {
  GuestOverviewReservation,
  useGetAllBookingsForGuestProfile
} from '../../api/guest/useGetAllBookingsForGuestProfile'
import { DateTime } from 'luxon'
import _ from 'lodash'
import {
  PageMissing404DesktopIllustration,
  PageMissing404MobileIllustration
} from '@toasttab/buffet-pui-illustrations'
import { BookingCard } from './BookingCard'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { PartialFrame } from '../Frame/PartialFrame'
import { useAuthOnMount } from '../../api/guest/constants'
import { useShowGuestAccounts } from './flags'
import { GuestBookingSkeletonView } from './GuestBookingSkeletonView'
import { BookingStatus } from '../../api/bookings/getBooking'
import { useRestaurantContext } from '../../contexts/RestaurantContext'
import { Helmet } from 'react-helmet-async'

export const GuestBookingsView = ({
  restaurant
}: {
  restaurant: RestaurantInfo
}) => {
  const showGuestAccounts = useShowGuestAccounts(restaurant)
  const navigate = useNavigate()
  const { isAuthenticatedConditional, guestData, isLoading } =
    useAuthOnMount(showGuestAccounts)
  const { data: bookings } = useGetAllBookingsForGuestProfile(guestData)
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false)
  const { shortUrl } = useRestaurantContext()

  const redirect = React.useCallback(() => {
    const findTimeUrl = `/${shortUrl}/findTime`
    navigate(findTimeUrl, { replace: true })
  }, [navigate, shortUrl])

  React.useEffect(() => {
    if (!isLoading && !isAuthenticatedConditional) {
      redirect()
    }
  }, [isAuthenticatedConditional, isLoading, redirect])

  if (!guestData || !bookings || isLoading) {
    return <GuestBookingSkeletonView restaurant={restaurant} />
  }

  if (bookings.length <= 0) {
    return (
      <PartialFrame restaurant={restaurant}>
        <Helmet>
          <title>Your Bookings | Toast Tables</title>
        </Helmet>
        <h1 className='type-large font-semibold mb-10'>My bookings</h1>
        <div className='w-full grid grid-rows-2 justify-center'>
          <div className='hidden md:block'>
            <PageMissing404DesktopIllustration />
          </div>
          <div className='block md:hidden'>
            <PageMissing404MobileIllustration />
          </div>
          <p className='mt-4 text-center'>
            You have no bookings available to view.
          </p>
        </div>
      </PartialFrame>
    )
  }

  const [past, upcoming] = _.partition(bookings, function (b) {
    return (
      isPastUtcDate(DateTime.fromISO(b.expectedStartTime).toJSDate()) ||
      b.bookingStatus === BookingStatus.R_CANCELLED
    )
  })

  function getSortedBookingsAscending(bookings: GuestOverviewReservation[]) {
    return bookings
      .sort((a, b) =>
        DateTime.fromISO(a.expectedStartTime).toJSDate() <
        DateTime.fromISO(b.expectedStartTime).toJSDate()
          ? -1
          : DateTime.fromISO(a.expectedStartTime).toJSDate() >
            DateTime.fromISO(b.expectedStartTime).toJSDate()
          ? 1
          : 0
      )
      .map((b, i) => (
        <BookingCard
          booking={b}
          past={false}
          openUpdateModal={openUpdateModal}
          setOpenUpdateModal={setOpenUpdateModal}
          key={i}
        />
      ))
  }

  function getSortedBookingsDescending(bookings: GuestOverviewReservation[]) {
    return bookings
      .sort((a, b) =>
        DateTime.fromISO(a.expectedStartTime).toJSDate() >
        DateTime.fromISO(b.expectedStartTime).toJSDate()
          ? -1
          : DateTime.fromISO(a.expectedStartTime).toJSDate() <
            DateTime.fromISO(b.expectedStartTime).toJSDate()
          ? 1
          : 0
      )
      .map((b, i) => (
        <BookingCard
          booking={b}
          past={true}
          openUpdateModal={openUpdateModal}
          setOpenUpdateModal={setOpenUpdateModal}
          key={i}
        />
      ))
  }

  return (
    <PartialFrame restaurant={restaurant}>
      <Helmet>
        <title>Your Bookings | Toast Tables</title>
      </Helmet>
      <div className='w-full justify-center flex flex-col gap-4'>
        <h1 className='type-large font-semibold'>My bookings</h1>
        <h2 className='font-semibold'>Upcoming ({upcoming.length})</h2>
        <div className='w-full flex flex-col gap-4'>
          {getSortedBookingsAscending(upcoming)}
        </div>
        <h2 className='font-semibold'>Past ({past.length})</h2>
        <div className='w-full flex flex-col gap-4'>
          {getSortedBookingsDescending(past)}
        </div>
      </div>
    </PartialFrame>
  )
}
