import React from 'react'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { FlourishStar } from './icons/FlourishStar'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useGetExperiences } from '../../api/experiences/useGetExperiences'
import { ExperienceCard } from './ExperienceCard'
import { Link } from 'react-router-dom'
import { useRestaurantContext } from '../../contexts/RestaurantContext'
import { DividerLine } from '../DividerLine/DividerLine'
import { DateTime } from 'luxon'

export const LimitedUpcomingExperiencesView = ({
  restaurant
}: {
  restaurant: RestaurantInfo
}) => {
  const { shortUrl } = useRestaurantContext()
  const currentDateTime = DateTime.now()
    .startOf('day')
    .toUTC()
    .toString()
    .replace('Z', '+00:00')
  const { data: experiences } = useGetExperiences(
    restaurant.guid,
    currentDateTime
  )

  if (!experiences || experiences.length === 0) {
    return null
  }

  return (
    <div className='grid grid-cols-2'>
      <div className='grid col-span-2'>
        <DividerLine />
      </div>
      <div className='flex items-center justify-self-start gap-1'>
        <FlourishStar />
        <span className='font-semibold type-large'>Experiences</span>
      </div>
      <div className='justify-self-end font-semibold'>
        <Button variant='text-link' as={Link} to={`/${shortUrl}/experiences`}>
          View all
        </Button>
      </div>
      <div className='grid col-span-2 gap-6'>
        <div className='text-secondary type-subhead'>
          Check out other exciting options {restaurant.name} has coming up.
        </div>
        <ul className='flex flex-col gap-6'>
          {experiences.length > 0 &&
            experiences.slice(0, 3).map((experience) => (
              <li key={experience.guid}>
                <ExperienceCard experience={experience} />
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}
