import { format, Formats } from '@toasttab/buffet-pui-date-utilities'
import { Locale } from '@toasttab/buffet-pui-locale-utilities'
import { formatDateStringToDate } from './formatDateStringToDate'
import { sortDates } from './sortDates'
import {
  Experience,
  getConsecutiveEnabledDaysString
} from '../api/experiences/model'

export const getFormattedDatesActive = (
  locale: Locale,
  experience: Experience,
  dateFormat: string = Formats.date.medium
) => {
  const { datesActive, alwaysAvailable } = experience

  if (alwaysAvailable) {
    return getConsecutiveEnabledDaysString(experience.shifts[0].hours) || ''
  }

  if (datesActive.length === 0) {
    return 'No dates available'
  }

  const sortedDates = sortDates(datesActive)

  switch (sortedDates.length) {
    case 1:
      return format(formatDateStringToDate(sortedDates[0]), dateFormat, locale)
    case 2:
      return `${format(
        formatDateStringToDate(sortedDates[0]),
        dateFormat,
        locale
      )} and ${format(
        formatDateStringToDate(sortedDates[1]),
        dateFormat,
        locale
      )}`
    default:
      const firstDate = format(
        formatDateStringToDate(sortedDates[0]),
        dateFormat,
        locale
      )
      const lastDate = format(
        formatDateStringToDate(sortedDates[sortedDates.length - 1]),
        dateFormat,
        locale
      )
      return `Multiple dates between ${firstDate} and ${lastDate}`
  }
}
