import React from 'react'
import { Experience } from '../../api/experiences/model'
import { useBanquetProps } from 'banquet-runtime-modules'

export const ExperienceViewImage = ({
  experience
}: {
  experience: Experience
}) => {
  const { featureFlags } = useBanquetProps()

  const hasExperienceImagesFlag = Boolean(
    featureFlags?.['nv1-experience-images']
  )

  const hasExperienceImages =
    hasExperienceImagesFlag &&
    experience.imageConfig?.mobileImageLocation &&
    experience.imageConfig?.desktopImageLocation

  if (hasExperienceImages) {
    return (
      <div className='relative w-full aspect-[1/1] xxs:aspect-[21/9] xl:my-8'>
        <picture>
          <source
            media='(min-width: 480px)'
            srcSet={experience.imageConfig?.desktopImageLocation}
          />
          <img
            src={experience.imageConfig?.mobileImageLocation}
            alt=''
            className='absolute inset-0 w-full h-full object-cover xl:rounded-lg'
          />
        </picture>
      </div>
    )
  }

  return null
}
