import { CalendarTodayIcon, GuestIcon } from '@toasttab/buffet-pui-icons'
import React from 'react'
import { Experience } from '../../api/experiences/model'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import { Ticket } from './icons/Ticket'
import { getExperiencePartySizeRange } from '../../utils/getExperiencePartySizeRange'
import { getFormattedDatesActive } from '../../utils/getFormattedDatesActive'
import { useIntlProps } from 'banquet-runtime-modules'
import { Formats } from '@toasttab/buffet-pui-date-utilities'
import { ViewExperienceLink } from './ViewExperienceLink'
import { ExperienceCardImage } from './ExperienceCardImage'

type FullExperienceCardProps = {
  restaurant: RestaurantInfo
  experience: Experience
}

export const FullExperienceCard = ({ experience }: FullExperienceCardProps) => {
  const { language: locale } = useIntlProps()
  const formatCurrency = useFormatCurrency()
  const partySizeRange = getExperiencePartySizeRange(experience)
  const formattedDateRange = getFormattedDatesActive(
    locale,
    experience,
    Formats.date.medium_without_year
  )

  return (
    <div className='bg-white'>
      <div className='flex flex-col xxs:flex-row gap-y-3 p-4 sm:p-6 gap-x-6'>
        <ExperienceCardImage experience={experience} />
        <div className='flex flex-row relative justify-between flex-grow'>
          <div className='flex flex-col gap-2 w-full'>
            <div className='flex flex-col sm:flex-row items-start justify-between gap-4'>
              <div>
                <div className='flex flex-col font-semibold type-large mb-1 line-clamp-2'>
                  {experience.name}
                </div>
                {experience.depositConfig && (
                  <div className='flex items-center'>
                    <Ticket />
                    <span className='pb-0.5 pl-1 font-semibold type-subhead'>
                      From{' '}
                      {formatCurrency(experience.depositConfig.minimumDeposit)}{' '}
                      deposit /
                      {experience.depositConfig.strategy === 'PARTYSIZE'
                        ? ' person'
                        : ' booking'}
                    </span>
                  </div>
                )}
                {(experience.datesActive.length > 0 ||
                  experience.shifts.length > 0) && (
                  <div className='flex flex-col flex-wrap'>
                    <div>
                      <CalendarTodayIcon
                        size='xs'
                        accessibility='decorative'
                        className='pb-0.5'
                      />
                      <span className='pl-1 font-semibold type-subhead'>
                        {formattedDateRange}
                      </span>
                    </div>

                    {experience.shifts.length > 0 && (
                      <div className='flex flex-row items-center'>
                        <GuestIcon
                          size='xs'
                          accessibility='decorative'
                          className='pb-0.5'
                        />
                        <span className='pl-1 font-semibold type-subhead'>
                          Party size {partySizeRange}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                {experience.memo?.description && (
                  <p className='text-secondary line-clamp-4 mt-2'>
                    {experience.memo.description}
                  </p>
                )}
              </div>
              <div className='justify-self-end w-full sm:w-auto'>
                <ViewExperienceLink
                  experience={experience}
                  className='w-full sm:w-auto'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
