import { useMutation } from 'react-query'
import { useAvailabilitiesContext } from '../../api/availabilities/AvailabilitiesContext'

interface UpdateAvailabilitiesRequestProps {
  restaurantGuid: string
  startTime: string
  endTime: string
  partySize: number
  enabled: boolean
  specifiedBookableGuid?: string
}

export const getAvailabilitiesQueryKey = 'availabilities'

export const useUpdateAvailabilitiesRequest = () => {
  const { getAvailabilities } = useAvailabilitiesContext()

  return useMutation(
    ({
      restaurantGuid,
      startTime,
      endTime,
      partySize,
      specifiedBookableGuid
    }: UpdateAvailabilitiesRequestProps) =>
      getAvailabilities(
        restaurantGuid,
        startTime,
        endTime,
        partySize,
        specifiedBookableGuid
      )
  )
}
