import React from 'react'
import { SelectField } from '@toasttab/buffet-pui-forms'
import { GuestIcon } from '@toasttab/buffet-pui-icons'
import { FormikProps } from 'formik'
import { ReservationUpdateFormProps } from './models'
import { useTimeFinderForm } from '../../hooks/useReservationTimeSelectionViewForm'

interface PartySizeSelectorProps {
  isDateTimeInvalid: boolean
  formik: FormikProps<ReservationUpdateFormProps>
  disabled: boolean
}

export const PartySizeSelector = ({
  isDateTimeInvalid,
  formik,
  disabled
}: PartySizeSelectorProps) => {
  const { partySizes } = useTimeFinderForm()

  return (
    <SelectField
      value={isDateTimeInvalid ? '---' : formik.values.partySize}
      containerClassName='w-full'
      label='Party Size'
      name='partySize'
      iconLeft={<GuestIcon className='-ml-0.5 md:ml-0' />}
      options={partySizes.map((op) => ({
        ...op
      }))}
      disabled={disabled}
      placeholder={isDateTimeInvalid ? '---' : undefined}
    />
  )
}
