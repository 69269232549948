import React from 'react'
import { DateTime } from 'luxon'
import { useGetRestaurant } from '../../api/restaurant/useGetRestaurant'
import { WaitlistDetailEntryView } from './WaitlistDetailEntryView'
import { Frame } from '../Frame/Frame'
import { Helmet } from 'react-helmet-async'

export interface WaitlistDetailEntryContainerProps {
  restaurantGUID: string
}

export const WaitlistDetailEntryContainer = ({
  restaurantGUID
}: WaitlistDetailEntryContainerProps) => {
  const { data: restaurant } = useGetRestaurant(restaurantGUID, {
    slotTime: DateTime.now().toISO()
  })

  if (!restaurant) {
    return null
  }

  return (
    <Frame restaurant={restaurant}>
      <Helmet>
        <title>
          {restaurant.name} - Join Waitlist - {restaurant.city},{' '}
          {restaurant.state} | Toast Tables
        </title>
      </Helmet>
      <WaitlistDetailEntryView restaurant={restaurant} />
    </Frame>
  )
}
