import React from 'react'
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useResolvedPath
} from 'react-router-dom'
import { LoadingView } from '../LoadingView/LoadingView'
import { FindReserveContextProvider } from '../../contexts/find-reserve-context'
import { useGetRestaurant } from '../../api/restaurant/useGetRestaurant'
import { ReservationDetailEntryView } from '../ReservationDetailEntryView/ReservationDetailEntryView'
import { ReservationConfirmationContainer } from '../ReservationConfirmationView/ReservationConfirmationContainer'
import { ReservationDepositContainer } from '../ReservationDepositView/ReservationDepositContainer'
import { WaitlistDetailEntryContainer } from '../WaitlistDetailEntryView/WaitlistDetailEntryContainer'
import { GuestBookingsView } from '../GuestAccount/GuestBookingsView'
import { GuestProfileView } from '../GuestAccount/GuestProfileView'
import { ExperienceView } from '../ExperienceView/ExperienceView'
import { AllUpcomingExperiencesView } from '../ExperienceView/AllUpcomingExperiencesView'
import { useGetRwgTokenAndMerchantId } from '../../hooks/useRwgTokenAndMerchantId/useGetRwgTokenAndMerchantId'
import { Helmet, HelmetProvider } from 'react-helmet-async'

export interface RouteParams {
  restaurantGuid: string
}

export function AppContainer() {
  const { restaurantGuid } = window.TT_GLOBALS
  const { isError, data: restaurant } = useGetRestaurant(restaurantGuid, {
    slotTime: null
  })
  const resolvedPath = useResolvedPath('')
  const match = useMatch(`${resolvedPath.pathname}/*`)
  const reservePath = match ? `${match.pathnameBase}/reserve` : 'reserve'

  useGetRwgTokenAndMerchantId(restaurantGuid)

  if (!restaurant || isError) {
    return <LoadingView />
  }

  return (
    <FindReserveContextProvider>
      <HelmetProvider>
        <Helmet>Toast Tables</Helmet>
        <Routes>
          <Route
            path={`findTime`}
            element={<Navigate to={reservePath} replace />}
          />
          <Route
            path={`:restaurantGuid/reserve`}
            element={<ReservationDetailEntryView restaurant={restaurant} />}
          />
          <Route
            path={`reserve`}
            element={<ReservationDetailEntryView restaurant={restaurant} />}
          />
          <Route
            path={`joinWaitlist`}
            element={
              <WaitlistDetailEntryContainer restaurantGUID={restaurantGuid!} />
            }
          />
          <Route
            path={`:restaurantGuid/bookings/:bookingGuid/`}
            element={<ReservationConfirmationContainer />}
          />
          <Route
            path={`bookings/:bookingGuid/`}
            element={<ReservationConfirmationContainer />}
          />
          <Route
            path={`experience/:experienceSlug/`}
            element={<ExperienceView restaurant={restaurant} />}
          />
          <Route
            path={`payDeposit/:bookingGuid/`}
            element={<ReservationDepositContainer restaurant={restaurant} />}
          />
          <Route
            path='experiences'
            element={<AllUpcomingExperiencesView restaurant={restaurant} />}
          />
          <Route
            path={`account/profile`}
            element={<GuestProfileView restaurant={restaurant} />}
          />
          <Route
            path={`account/bookings`}
            element={<GuestBookingsView restaurant={restaurant} />}
          />
          {/* <Route path={`account/payments`} /> */}
        </Routes>
      </HelmetProvider>
    </FindReserveContextProvider>
  )
}
