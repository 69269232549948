export function getIsPastBookable(datesActive: string[]): boolean {
  if (!datesActive.length) return false // No dates, assume not past

  const latestDate = datesActive.reduce((latest, current) =>
    new Date(current).getTime() > new Date(latest).getTime() ? current : latest
  )

  const latestDateUTC = new Date(latestDate).setUTCHours(0, 0, 0, 0)
  const todayUTC = new Date().setUTCHours(0, 0, 0, 0)

  return latestDateUTC < todayUTC
}
