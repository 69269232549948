import { useAuth } from '@toasttab/do-secundo-guest-authentication'
import * as React from 'react'
import { NumberInput } from '@toasttab/buffet-pui-text-input'
import {
  phoneNumberFormat,
  phoneNumberPlaceholder
} from '@toasttab/buffet-pui-phone-utilities'
import { Reservation } from '../../api/bookings/getBooking'
import { Form, FormikProvider, useFormik } from 'formik'
import {
  GuestAccountPhoneEntryFormValues,
  getPhoneEntryInitialValues
} from './models'
import { phoneEntryValidationSchema } from './validationSchema'
import { SubmitButton } from '@toasttab/buffet-pui-forms'
import { DividerLine } from '../DividerLine/DividerLine'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { parsePhoneNumber } from '../../utils/parsePhoneNumber'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { AutorenewIcon } from '@toasttab/buffet-pui-icons'

const formId = 'PhoneNumberEntry'

type PhoneNumberEntryProps = {
  startPasswordlessSucceeded: (phoneNumber: string) => void
  booking?: Reservation
  fromBookingStep: boolean
  restaurant: RestaurantInfo
}

export const PhoneNumberEntry = ({
  startPasswordlessSucceeded,
  booking,
  fromBookingStep,
  restaurant
}: PhoneNumberEntryProps) => {
  const { startPasswordless } = useAuth()
  const initialValues = getPhoneEntryInitialValues(booking)
  const validationSchema = phoneEntryValidationSchema
  const [isError, setIsError] = React.useState(false)

  const onSubmit = async (values: GuestAccountPhoneEntryFormValues) => {
    const phoneNumber = values.phoneNumber
    const formattedPhoneNumber =
      phoneNumber.substring(0, 2) === '+1'
        ? phoneNumber
        : `+1${values.phoneNumber}`

    try {
      const response = await startPasswordless(
        formattedPhoneNumber,
        'TOAST_TABLES'
      )

      const responseType = response.data?.setupGuest?.__typename
      if (
        responseType === 'SetupGuestSuccess' ||
        responseType === 'UnexpectedAuthError'
      ) {
        setIsError(false)
        startPasswordlessSucceeded(formattedPhoneNumber)
      } else {
        setIsError(true)
      }
    } catch (err) {
      setIsError(true)
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true
  })

  const [phoneNumber, setPhoneNumber] = React.useState(
    parsePhoneNumber(formik.values.phoneNumber)
  )
  const formatPhoneNumber = phoneNumberFormat(phoneNumber, restaurant.country)

  const formatProps = formatPhoneNumber.length
    ? {
        format: formatPhoneNumber,
        mask: ' '
      }
    : {}

  const placeholder = phoneNumberPlaceholder(restaurant.country)

  return (
    <FormikProvider value={formik}>
      <Form id={formId}>
        <h3 className='type-large font-semibold mb-4'>
          Enter your phone number
        </h3>
        {!fromBookingStep && (
          <p className='text-secondary mb-4'>
            If you don't have a Toast account, we will help you create one.
          </p>
        )}
        <div className='mb-2'>
          <NumberInput
            testId='login-phone-entry'
            aria-label='Phone number'
            containerClassName='w-full'
            type='tel'
            required
            autoFocus
            name='phoneNumber'
            placeholder={placeholder}
            {...formatProps}
            value={phoneNumber}
            invalid={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            errorText={
              <>{formik.touched.phoneNumber && formik.errors.phoneNumber}</>
            }
            onChange={(data) => {
              const { value } = data
              setPhoneNumber(data.value)
              formik.setFieldTouched('phoneNumber')
              formik.setFieldValue('phoneNumber', value)
            }}
          />
        </div>
        <DividerLine />
        <div className='mb-4'>
          <p className='w-full text-secondary type-caption mb-2'>
            We will send a one-time verification code to verify your Toast
            Account. Your phone number will be used to authenticate and provide
            you with automated messages related to your transactions with Toast
            and Toast restaurants.
          </p>
          <p className='w-full text-secondary type-caption'>
            Msg frequency varies. Msg & data rates may apply.
          </p>
        </div>
        {isError && (
          <div className='m-auto w-full flex items-start mb-4'>
            <Alert showIcon variant='error' className='w-full'>
              Oops! Looks like we&apos;ve hit a little bump; please try again.
            </Alert>
          </div>
        )}
        <div className='py-2.5 pb-4'>
          <SubmitButton
            form={formId}
            testId='phone-number-entry-button'
            size='base'
            className='w-full'
            disabled={
              formik.isSubmitting ||
              (!(formik.isValid && formik.dirty) && !booking)
            }
            iconLeft={
              formik.isSubmitting ? (
                <AutorenewIcon
                  className='animate-spin'
                  accessibility='decorative'
                />
              ) : null
            }
          >
            Continue
          </SubmitButton>
        </div>
      </Form>
    </FormikProvider>
  )
}
