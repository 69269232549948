import axios from 'axios'
import { useQuery } from 'react-query'
import { NvResponse, RequestName, RequestURL } from '../baseModels'
import { createG2Request } from '../createRequest'

type WaitlistInfoResponse = {
  isOpenNow: boolean
  minPartySize: number
  maxPartySize: number
}

export const getWaitlistInfoQueryKey = 'getWaitlistInfo'

export const getWaitlistInfo = async (restaurantGuid: string) =>
  axios
    .request<NvResponse<WaitlistInfoResponse>>(
      createG2Request<never>(
        RequestURL[RequestName.GET_WAITLIST_INFO],
        {
          'Toast-Restaurant-External-ID': restaurantGuid
        },
        { restaurantGuid }
      )
    )
    .then((response) => response.data.results[0])

export const useGetWaitlistInfo = () => {
  const { restaurantGuid } = window.TT_GLOBALS

  return useQuery<WaitlistInfoResponse>([getWaitlistInfoQueryKey], () =>
    getWaitlistInfo(restaurantGuid)
  )
}
