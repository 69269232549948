import React, { useEffect } from 'react'
import cx from 'classnames'
import { Link, useNavigate } from 'react-router-dom'
import { Frame } from '../Frame/Frame'
import { UpdateSearchButton } from '../UpdateSearchButton/UpdateSearchButton'
import { ExpiredBookingAlert } from '../ExpiredBookingAlert/ExpiredBookingAlert'
import { ReservationContentWrapper } from '../ReservationContentWrapper/ReservationContentWrapper'
import { AvailabilitiesSection } from '../AvailabilitiesSection/AvailabilitiesSection'
import { useSetupExperienceReservationParams } from '../../hooks/useSetupExperienceReservationParams'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { Ticket } from './icons/Ticket'
import { CalendarTodayIcon, GuestIcon } from '@toasttab/buffet-pui-icons'
import { useFormatCurrency } from '../../hooks/useFormatCurrency'
import { getExperiencePartySizeRange } from '../../utils/getExperiencePartySizeRange'
import { getFormattedDatesActive } from '../../utils/getFormattedDatesActive'
import { useBanquetProps, useIntlProps } from 'banquet-runtime-modules'
import { NewLineFormatter } from '../NewLineFormatter/NewLineFormatter'
import { useGetExperience } from '../../api/experiences/useGetExperience'
import { LoadingView } from '../LoadingView/LoadingView'
import { useRestaurantContext } from '../../contexts/RestaurantContext'
import { Helmet } from 'react-helmet-async'
import { getIsPastBookable } from '../../utils/getIsPastBookable'
import { ExperienceViewImage } from './ExperienceViewImage'
import { DividerLine } from '../DividerLine/DividerLine'

export const ExperienceView = ({
  restaurant
}: {
  restaurant: RestaurantInfo
}) => {
  const { featureFlags } = useBanquetProps()
  const navigate = useNavigate()
  const { shortUrl } = useRestaurantContext()
  const { language: locale } = useIntlProps()
  const formatCurrency = useFormatCurrency()
  const { experience, isLoading } = useGetExperience()

  useSetupExperienceReservationParams(restaurant, experience)

  useEffect(() => {
    // Scroll to the top when the page is navigated to
    window.scrollTo(0, 0)
  }, [])

  // If the experience doesn't exist or it's in the past
  // Redirect the user to the main reservation page
  useEffect(() => {
    if (
      !isLoading &&
      (!experience || getIsPastBookable(experience.datesActive))
    ) {
      navigate(`/${shortUrl}/reserve`, {
        replace: true
      })
    }
  }, [isLoading, experience, shortUrl, navigate])

  if (!experience) {
    return <LoadingView />
  }

  const depositDetail =
    experience?.depositConfig && experience.depositConfig.minimumDeposit > 0
      ? `From ${formatCurrency(
          experience.depositConfig.minimumDeposit
        )} deposit /
                  ${
                    experience.depositConfig.strategy === 'PARTYSIZE'
                      ? 'person'
                      : 'booking'
                  }`
      : null
  const partySizeRange = getExperiencePartySizeRange(experience)
  const formattedDateRange = getFormattedDatesActive(locale, experience)

  const hasExperienceImagesFlag = Boolean(
    featureFlags?.['nv1-experience-images']
  )
  const hasExperienceImages =
    hasExperienceImagesFlag && Boolean(experience?.imageConfig)

  return (
    <Frame
      restaurant={restaurant}
      showCoverPhoto={false}
      hasNoPadding={hasExperienceImages}
    >
      <Helmet>
        <title>
          {restaurant.name} - {experience.name} - {restaurant.city},{' '}
          {restaurant.state} | Toast Tables
        </title>
      </Helmet>
      <ExperienceViewImage experience={experience} />
      <div
        className={cx('flex flex-col gap-6', {
          'p-4 xl:p-0': hasExperienceImages
        })}
      >
        <ExpiredBookingAlert />
        <ReservationContentWrapper restaurant={restaurant}>
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col gap-2'>
              <h1 className='type-headline-4'>{experience.name}</h1>
              <p>
                By{' '}
                <Link
                  to={`/${shortUrl}/findTime`}
                  className='underline hover:no-underline'
                >
                  {restaurant.name}
                </Link>
              </p>
            </div>
            <div className='flex flex-col gap-2'>
              <UpdateSearchButton restaurant={restaurant} />
            </div>
            <AvailabilitiesSection
              restaurant={restaurant}
              bookableGuid={experience?.guid || ''}
            />
            <DividerLine />
            <div className='flex flex-col gap-2'>
              <h2 className='type-large font-semibold'>Details</h2>
              <ul className='flex flex-col gap-1'>
                {depositDetail && (
                  <li className='flex items-center gap-2'>
                    <Ticket width={24} height={24} />
                    {depositDetail}
                  </li>
                )}

                <li className='flex items-center gap-2'>
                  <GuestIcon accessibility='decorative' />
                  Party size {partySizeRange}
                </li>
                <li className='flex items-center gap-2'>
                  <CalendarTodayIcon accessibility='decorative' />
                  {formattedDateRange}
                </li>
              </ul>
            </div>
            {experience.memo?.description && (
              <NewLineFormatter
                text={experience.memo.description}
                className='text-secondary'
              />
            )}
          </div>
        </ReservationContentWrapper>
      </div>
    </Frame>
  )
}
