import React from 'react'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { RestaurantInfo } from '../../api/restaurant/getRestaurant'
import { Alert } from '@toasttab/buffet-pui-alerts'

export interface WaitEstimatorProps {
  restaurant: RestaurantInfo
  partiesAheadNum: number
  estimatedLowerBound: number | null
  estimatedUpperBound: number | null
  isLoading: boolean
}

export function getWaitTimeRange(
  estimatedLowerBound: number | null,
  estimatedUpperBound: number | null
) {
  if (estimatedLowerBound && estimatedUpperBound) {
    if (estimatedLowerBound === estimatedUpperBound) {
      return `${estimatedLowerBound} mins`
    }
    return `${estimatedLowerBound}-${estimatedUpperBound} mins`
  }
  return '0 mins'
}

export function WaitEstimator({
  restaurant,
  partiesAheadNum,
  estimatedLowerBound,
  estimatedUpperBound,
  isLoading
}: WaitEstimatorProps) {
  const showUnableToCalculateWaitTime =
    restaurant.showWaitlistEstimate &&
    (estimatedLowerBound === null || estimatedUpperBound === null)

  return (
    <div className='flex flex-col justify-center gap-4 py-4'>
      <div className='flex flex-col items-center'>
        <div className='flex flex-col items-center'>
          {isLoading ? (
            <div className='flex justify-center items-center h-24'>
              <MerryGoRound size='md' />
              <div className='sr-only' role='status'>
                Loading wait time estimate...
              </div>
            </div>
          ) : (
            <div className='flex gap-x-2'>
              {partiesAheadNum
                .toString()
                .padStart(2, '0')
                .split('')
                .map((numStr, index) => (
                  <div
                    data-testid='partyAheadNum'
                    key={'partyAheadNum' + numStr + index}
                    className='flex justify-center align-middle text-align-center gap-2 bg-gray-0 bg-center rounded-lg h-15 w-15 px-2 py-4'
                  >
                    <div
                      className='font-effra font-medium bg-clip-text bg-gradient-to-r from-brand-50 to-primary-50'
                      style={{
                        color: 'transparent',
                        fontSize: '4rem',
                        lineHeight: '1'
                      }}
                    >
                      {numStr}
                    </div>
                  </div>
                ))}
            </div>
          )}
          <p className='text-center pt-2'>
            <b>Parties ahead of you</b>
          </p>
          {restaurant.showWaitlistEstimate && (
            <p className='text-center pb-1'>
              Your approx. wait time is{' '}
              <span className='font-bold'>
                {showUnableToCalculateWaitTime
                  ? 'Unavailable'
                  : getWaitTimeRange(estimatedLowerBound, estimatedUpperBound)}
              </span>
            </p>
          )}
        </div>
        <p className='text-secondary text-center'>
          {' '}
          Please note that parties ahead are determined by the dining area
          selected below.{' '}
        </p>
      </div>
      {showUnableToCalculateWaitTime && (
        <Alert variant='warning'>
          Once you join the waitlist, please check in with the host when you
          arrive for the latest update on wait times.
        </Alert>
      )}
    </div>
  )
}
