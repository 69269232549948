import { useEffect } from 'react'
import { usePartySize } from './usePartySize'
import { useUpdateQueryParams } from './useUpdateQueryParams'
import { RestaurantInfo } from '../api/restaurant/getRestaurant'
import { Experience } from '../api/experiences/model'
import { getNextEnabledDateTime } from '../utils/getNextEnabledDateTime'
import { useGetDateTimeParam } from './useGetDateTimeParam/useGetDateTimeParam'
import { isValidDateTime } from '../utils/isValidDateTime'
import { parseEpochOrIsoToUtc } from '../utils/parseEpochOrIsoToUtc'

export const useSetupExperienceReservationParams = (
  restaurant: RestaurantInfo,
  experience: Experience | undefined
) => {
  const updateQueryParams = useUpdateQueryParams()
  const { defaultPartySizeIfNoneChosen, partySize, partySizeParam } =
    usePartySize()
  const getDateTimeParam = useGetDateTimeParam()

  const timeSlotParam = getDateTimeParam('timeSlot')
  const timeSlotDateTime = parseEpochOrIsoToUtc(timeSlotParam)
  const timeSlotString = timeSlotDateTime.toLocal().toISO()
  const isTimeSlotValid = timeSlotDateTime.isValid
  const dateTimeString = getDateTimeParam('dateTime')
  const isDateTimeValid = isValidDateTime(dateTimeString)

  useEffect(() => {
    // if user omits OR manipulates partySize param to be not a number, set default party size
    if (!partySizeParam) {
      updateQueryParams({
        partySize: defaultPartySizeIfNoneChosen.toString()
      })
      return
    }
    // if the URL has a timeSlot query param (linked from RWG or LBT)
    // use that timeSlot value to search for availabilities on the page
    if (isTimeSlotValid) {
      updateQueryParams({
        dateTime: timeSlotString
      })
      // if user omits OR manipulates dateTime param to be not a valid date, set to closest future time
    } else if (!isDateTimeValid && experience) {
      const nextEnabledDateTime = getNextEnabledDateTime(
        experience,
        restaurant.timezone
      )

      updateQueryParams({
        dateTime: nextEnabledDateTime.toISO()
      })
    }
    // if user manipulates partySize param to a number outside of minPartySize to maxPartySize range
    // set to either minPartySize or maxPartySize
    if (partySizeParam !== partySize) {
      updateQueryParams({
        partySize: partySize.toString()
      })
    }
  }, [
    defaultPartySizeIfNoneChosen,
    partySizeParam,
    partySize,
    updateQueryParams,
    isDateTimeValid,
    restaurant,
    experience,
    isTimeSlotValid,
    timeSlotString
  ])
}
