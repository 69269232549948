import { UseQueryResult } from 'react-query'
import { OnlineReservationHoursInterval } from '../../api/availabilities/onlineReservationHours/getOnlineReservationHours'
import { DayInfo } from '../../api/restaurant/useGetDayInfo'
import { SelectOption } from '../../common/types'

export const getIsBlocked = ({
  isExperience,
  dayInfo
}: {
  isExperience: boolean
  dayInfo: DayInfo | undefined
}) =>
  !isExperience && dayInfo !== undefined && dayInfo.onlineReservationsBlocked

export const getIsRestaurantClosed = ({
  isExperience,
  isBlocked,
  hoursData
}: {
  isExperience: boolean
  isBlocked: boolean
  hoursData: UseQueryResult<OnlineReservationHoursInterval[], unknown>
}) =>
  Boolean(
    !isExperience &&
      !isBlocked &&
      hoursData?.data &&
      !hoursData.data?.[0]?.start
  )

export const getIsAfterHours = ({
  isExperience,
  isBlocked,
  isRestaurantClosed,
  hoursOptions
}: {
  isExperience: boolean
  isBlocked: boolean
  isRestaurantClosed: boolean
  hoursOptions: SelectOption[]
}) =>
  !isExperience &&
  !isRestaurantClosed &&
  !isBlocked &&
  hoursOptions.length > 0 &&
  hoursOptions.every(({ value }) => new Date() > new Date(value))
