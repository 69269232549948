import { capitalize } from '../../utils/capitalize'
import { DateTimeString } from '../baseModels'

export interface Memo {
  name: string
  description: string
}

export interface ExperienceHours {
  sunday: {
    enabled: boolean
    start: string
    end: string
  }
  monday: {
    enabled: boolean
    start: string
    end: string
  }
  tuesday: {
    enabled: boolean
    start: string
    end: string
  }
  wednesday: {
    enabled: boolean
    start: string
    end: string
  }
  thursday: {
    enabled: boolean
    start: string
    end: string
  }
  friday: {
    enabled: boolean
    start: string
    end: string
  }
  saturday: {
    enabled: boolean
    start: string
    end: string
  }
}

// TODO: Make this i18n friendly using Intl.DateTimeFormat
const shortenDay = (dayOfWeek: string) => dayOfWeek.slice(0, 3)

const capitalizeAndShorten = (dayOfWeek: string) =>
  capitalize(shortenDay(dayOfWeek))

export function getConsecutiveEnabledDaysString(
  hours: ExperienceHours
): string {
  const weekOrder: Array<keyof ExperienceHours> = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ]

  const enabledDays = weekOrder.filter((day) => hours[day].enabled)
  if (enabledDays.length === 0) return ''

  const groups: Array<Array<keyof ExperienceHours>> = []
  let currentGroup: Array<keyof ExperienceHours> = []

  for (const day of enabledDays) {
    if (currentGroup.length === 0) {
      currentGroup.push(day)
    } else {
      const lastDay = currentGroup[currentGroup.length - 1]
      const lastIndex = weekOrder.indexOf(lastDay)
      const currentIndex = weekOrder.indexOf(day)
      if (currentIndex === lastIndex + 1) {
        currentGroup.push(day)
      } else {
        groups.push(currentGroup)
        currentGroup = [day]
      }
    }
  }
  if (currentGroup.length > 0) groups.push(currentGroup)

  const formatGroup = (group: Array<keyof ExperienceHours>): string => {
    if (group.length === 1) {
      return capitalizeAndShorten(group[0])
    } else {
      return `${capitalizeAndShorten(group[0])} - ${capitalizeAndShorten(
        group[group.length - 1]
      )}`
    }
  }

  const formattedGroups =
    groups.length === 1 && groups[0].length === 1
      ? [capitalize(groups[0][0])]
      : groups.map(formatGroup)

  let result = 'Every '
  if (formattedGroups.length === 1) {
    result += formattedGroups[0]
  } else if (formattedGroups.length === 2) {
    result += `${formattedGroups[0]}, ${formattedGroups[1]}`
  } else {
    result += formattedGroups.map(shortenDay).join(', ')
  }

  return result
}

interface ExperienceShift {
  guid: string
  name: string
  maxPartySize: number
  minPartySize: number
  timeSlotSize: number
  hours: ExperienceHours
  bookingMinMinutesInAdvance: number
}

interface PublicExperienceDepositConfig {
  strategy: 'PARTYSIZE' | 'BOOKING'
  minimumDeposit: number
}

export type ExperienceImageLocation = {
  mobileImageLocation: string
  desktopImageLocation: string
}

export interface Experience {
  guid: string
  name: string
  datesActive: DateTimeString[]
  alwaysAvailable: boolean
  memo: Memo | null
  slug: string | null
  shifts: ExperienceShift[]
  depositConfig: PublicExperienceDepositConfig | null
  imageConfig: ExperienceImageLocation | null
}
