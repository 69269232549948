import React from 'react'
import { useBanquetProps } from 'banquet-runtime-modules'
import { TableIllustration } from '@toasttab/buffet-pui-illustrations'
import { Experience } from '../../api/experiences/model'

export const ExperienceCardImage = ({
  experience
}: {
  experience: Experience
}) => {
  const { featureFlags } = useBanquetProps()

  const hasExperienceImagesFlag = Boolean(
    featureFlags?.['nv1-experience-images']
  )

  const hasExperienceImages =
    hasExperienceImagesFlag && experience.imageConfig?.mobileImageLocation

  if (hasExperienceImages) {
    return (
      <div className='relative w-full aspect-[1/1] xxs:max-w-60 flex-shrink-0'>
        <img
          src={experience.imageConfig?.mobileImageLocation}
          alt=''
          className='absolute inset-0 w-full h-full object-cover rounded-lg'
        />
      </div>
    )
  }

  if (hasExperienceImagesFlag) {
    return (
      <div className='flex flex-col justify-center items-center aspect-[1/1] xxs:max-w-60 rounded-lg flex-shrink-0  bg-gray-0'>
        <TableIllustration resizeToContainer />
      </div>
    )
  }

  return null
}
